import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { registerDevice } from '../apis/backend';

interface NotificationConfig {
  notificationTypes: string[];
  watchlistSymbols: string[];
}
interface NotificationData {
  notification_type?: string;
  symbols?: string;
  [key: string]: any;
}

export class PushNotificationService {
  private static currentToken: string | null = null;
  private static isInitialized = false;

  static async initialize(config: NotificationConfig): Promise<boolean> {
    try {
      console.log('🚀 Initializing push notification service');
      console.log('Platform: ' + Capacitor.getPlatform());

      if (Capacitor.isNativePlatform()) {
        // Native implementation
        try {
          const permissionStatus = await FirebaseMessaging.requestPermissions();

          if (permissionStatus.receive === 'granted') {
            console.log('[PUSH-SERVICE] 🎯 Permission granted, getting FCM token...');

            const { token } = await FirebaseMessaging.getToken();
            console.log('🔔 FCM Token received: ' + token.slice(0, 10) + '...');
            this.currentToken = token;
            await this.sendTokenToBackend(config);

            FirebaseMessaging.addListener('notificationReceived', async (notification) => {
              console.log('[PUSH-SERVICE] 📨 Notification received:', notification);
              const notificationData = (notification.notification?.data || {}) as NotificationData;
              await FirebaseAnalytics.logEvent({
                name: 'notification_received',
                params: {
                  notification_type: notificationData.notification_type || 'unknown',
                  symbols: notificationData.symbols || '',
                  platform: Capacitor.getPlatform(),
                  app_state: 'foreground',
                  timestamp: new Date().toISOString(),
                },
              });
            });

            FirebaseMessaging.addListener('notificationActionPerformed', async (notification) => {
              console.log('[PUSH-SERVICE] 🔔 Notification tapped:', notification);
              const notificationData = (notification.notification?.data || {}) as NotificationData;
              await FirebaseAnalytics.logEvent({
                name: 'notification_opened',
                params: {
                  notification_type: notificationData.notification_type || 'unknown',
                  symbols: notificationData.symbols || '',
                  platform: Capacitor.getPlatform(),
                  timestamp: new Date().toISOString(),
                },
              });
            });
            return true;
          } else {
            console.log('❌ Permissions denied');
            return false;
          }
        } catch (error) {
          console.error('[PUSH-SERVICE] Error in native implementation:', error);
          return false;
        }
      } else if (Capacitor.getPlatform() === 'web') {
        // Web implementation
        try {
          console.log('[PUSH-SERVICE] 💻 Starting web configuration');

          // Dynamically import Firebase messaging for web only
          const { messaging } = await import('../config/firebase.config');
          const { getToken, onMessage } = await import('firebase/messaging');

          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          console.log('[PUSH-SERVICE] Service worker registered');

          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            console.log('[PUSH-SERVICE] 🎯 Web permissions granted');

            const currentToken = await getToken(messaging, {
              vapidKey: import.meta.env.VITE_APP_VAPID_KEY,
              serviceWorkerRegistration: registration,
            });

            if (currentToken) {
              this.currentToken = currentToken;
              console.log('🔔 Web token received: ' + currentToken.slice(0, 10) + '...');
              await this.sendTokenToBackend(config);
            }

            onMessage(messaging, (payload) => {
              console.log('[PUSH-SERVICE] 📨 Message received:', payload);
              console.log(payload.notification?.title || 'New notification');
            });
            return true;
          } else {
            console.log('❌ Web permissions denied');
            return false;
          }
        } catch (error) {
          console.error('[PUSH-SERVICE] Error in web implementation:', error);
          return false;
        }
      } else {
        console.log('[PUSH-SERVICE] Platform not supported:', Capacitor.getPlatform());
        return false;
      }
    } catch (error) {
      console.error('[PUSH-SERVICE] General error:', error);
      console.log('❌ ERROR in push notifications');
      return false;
    }
  }

  static async updateConfig(config: NotificationConfig): Promise<boolean> {
    if (!this.isInitialized) {
      try {
        const initialized = await this.initialize(config);
        if (!initialized) return false;
      } catch (error) {
        console.error('[PUSH-SERVICE] Failed to initialize:', error);
        return false;
      }
    }

    if (!this.currentToken) return false;
    try {
      await this.sendTokenToBackend(config);
      return true;
    } catch (error) {
      console.error('[PUSH-SERVICE] Error updating config:', error);
      return false;
    }
  }

  static async sendTokenToBackend(config: NotificationConfig) {
    if (!this.currentToken) throw new Error('No token available');
    try {
      const platform = Capacitor.getPlatform();
      console.log(`[PUSH-SERVICE] Registering ${platform} device:`, {
        token: this.currentToken.substring(0, 10) + '...',
        notificationTypes: config.notificationTypes,
        watchlistSymbols: config.watchlistSymbols,
      });
      return await registerDevice(this.currentToken, platform, {
        notificationTypes: config.notificationTypes,
        watchlistSymbols: config.watchlistSymbols,
      });
    } catch (error) {
      console.error('[PUSH-SERVICE] Backend error:', error);
      throw error;
    }
  }

  static async logNotificationEvent(eventName: string, notificationData: any) {
    try {
      await FirebaseAnalytics.logEvent({
        name: eventName,
        params: {
          ...notificationData,
          platform: Capacitor.getPlatform(),
          timestamp: new Date().toISOString(),
        },
      });
    } catch (error) {
      console.error('[PUSH-SERVICE] Error logging notification event:', error);
    }
  }
}
