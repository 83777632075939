import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { spacing, fontStyles, fontFamilies } from '../../styles';
import BottomLineExplanationTakeover from '../BottomLineExplanationTakeover';
import Button from '../Button';
import OnboardingSlide from './OnboardingSlide';

// Light theme images
import calendarLight from './images/calendar.svg';
import wiimLight from './images/wiim.svg';
import collectionsLight from './images/collections.svg';
import reportsLight from './images/reports.svg';
import discordLight from './images/discord.svg';

// Dark theme images
import calendarDark from './images/dark/calendar.svg';
import wiimDark from './images/dark/wiim.svg';
import collectionsDark from './images/dark/collections.svg';
import reportsDark from './images/dark/reports.svg';
import discordDark from './images/dark/discord.svg';

const OnboardingPage: FC = () => {
  const history = useHistory();
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const [checkedSlides, setCheckedSlides] = useState<number[]>([]);

  const handleComplete = () => {
    localStorage.setItem('hasCompletedOnboarding', 'true');
  };

  const toggleSlide = (index: number) => {
    setCheckedSlides((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };

  const slides = [
    {
      lightImage: wiimLight,
      darkImage: wiimDark,
      description: 'Understand why stock prices move.',
    },
    {
      lightImage: discordLight,
      darkImage: discordDark,
      description: 'Get your investing questions answered.',
    },
    {
      lightImage: calendarLight,
      darkImage: calendarDark,
      description: 'Track long-term changes in your watchlist.',
    },
    {
      lightImage: collectionsLight,
      darkImage: collectionsDark,
      description: 'Discover new investment opportunities.',
    },
    {
      lightImage: reportsLight,
      darkImage: reportsDark,
      description: 'Get the bottom line on company finances.',
    },
  ];

  return (
    <div
      css={css`
        min-height: 100vh;
        background-color: var(--background-color);
        padding: ${spacing.regular};
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 80px;
      `}
    >
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => {
          setIsBottomLineExplanationShown(false);
          history.push('/watchlist-search');
        }}
      />

      {/* Header */}
      <div
        css={css`
          text-align: center;
          margin-top: ${spacing.giga};
          margin-bottom: ${spacing.hecto};
        `}
      >
        <h1
          css={css`
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: ${spacing.regular};
            color: var(--text-color);
            font-family: ${fontFamilies.helveticaNowText};
          `}
        >
          Welcome to Bloom!
        </h1>
        <p
          css={css`
            ${fontStyles.dog};
          `}
        >
          How can we help you invest with confidence?
        </p>
      </div>

      {/* Slides */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing.milli};
        `}
      >
        {slides.map((slide, index) => (
          <div key={index}>
            <OnboardingSlide
              description={slide.description}
              lightImage={slide.lightImage}
              darkImage={slide.darkImage}
              isChecked={checkedSlides.includes(index)}
              onToggle={() => toggleSlide(index)}
            />
          </div>
        ))}
      </div>

      {/* Footer/Actions */}
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: ${spacing.regular};
          background-color: var(--background-color);
          margin-bottom: ${spacing.kilo};
        `}
      >
        <Button
          onClick={() => {
            handleComplete();
            history.push('/watchlist-search');
          }}
          css={css`
            width: 100%;
            height: 48px;
            font-size: 16px;
            font-weight: 500;
          `}
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default OnboardingPage;
