import { FC, HTMLProps } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { css } from '@emotion/react';
import { InvestmentTable } from '../InvestmentTable';
import { ColumnSelectionType } from '../InvestmentTable/columns';
import { TimeRange } from '../../types';
import { getRelatedCollections, getStockPeers } from '../../apis/backend';
import Hr from '../Hr';
import { colors, fontStyles, spacing } from '../../styles';
import RelatedCollections from './RelatedCollections';

interface Props extends HTMLProps<HTMLDivElement> {
  symbol: string;
}

const Related: FC<Props> = ({ symbol, ...props }) => {
  const investmentTableData = useQuery(['investments', symbol, 'peers'], async () => getStockPeers(symbol));
  const dataCollections: UseQueryResult<Omit<Collection, 'investments'>[]> = useQuery(
    ['collections', symbol],
    async () => getRelatedCollections(symbol),
  );
  const showCollections: boolean = (dataCollections.data && dataCollections.data.length > 0) ?? false;
  const showInvestments: boolean = !(!investmentTableData.data || investmentTableData.data.length < 1) ?? true;
  return (
    <div {...props}>
      {showCollections || showInvestments ? <Hr /> : null}
      {showCollections && (
        <div>
          <div>
            <p
              css={css`
                ${fontStyles.lion};
                margin: 0 0 ${spacing.regular} 0;
                font-size: 16px;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Appears in
            </p>
          </div>
          <RelatedCollections dataCollections={dataCollections} />
        </div>
      )}
      {showInvestments && (
        <div>
          <div>
            <p
              css={css`
                ${fontStyles.lion};
                margin: 0 0 ${spacing.regular} 0;
                font-size: 16px;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Related industry peers
            </p>
          </div>
          <InvestmentTable
            investments={investmentTableData.data!}
            columnSelections={[
              { type: ColumnSelectionType.Name },
              { type: ColumnSelectionType.Symbol },
              { type: ColumnSelectionType.ChangePercent, timeRange: TimeRange.months1 },
            ]}
            sortingRule={{ id: 'name' }}
          />
        </div>
      )}
    </div>
  );
};

export default Related;
