import { useState, FC, useEffect } from 'react';
import { Purchases } from '@revenuecat/purchases-capacitor'; // Updated import
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { Capacitor } from '@capacitor/core';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { App } from '@capacitor/app';
import { discordInviteUrl } from '../../constants';
import * as stores from '../../stores';
import Page from '../Page';
import { colors, spacing, fontStyles } from '../../styles';
import FeedbackTakeover from '../FeedbackTakeover';
import share from '../../utils/share';
import { getInitialTheme, setTheme } from '../../utils/themeManager';
import type { Theme } from '../../utils/themeManager';
import { useNotificationPreferences } from '../../hooks/useNotificationPreferences';
import Button from '../Button';
import RowButton from './RowButton';
import ThemeSelector from './ThemeSelector';

const MorePage: FC = () => {
  const history = useHistory();
  const [isFeedbackTakeoverShown, setIsFeedbackTakeoverShown] = useState(false);
  const restoreMutation = useMutation(() => Purchases.restorePurchases()); // Updated method
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;
  const [currentTheme, setCurrentTheme] = useState<Theme>(() => getInitialTheme());
  const [hasNotificationPermission, setHasNotificationPermission] = useState<boolean>(false);
  const { preferences, handleToggle } = useNotificationPreferences();

  useEffect(() => {
    const checkPermissionStatus = async () => {
      if (Capacitor.getPlatform() === 'web') return;

      const { receive } = await FirebaseMessaging.checkPermissions();
      setHasNotificationPermission(receive === 'granted');
    };

    // Check initial permission status when component mounts
    checkPermissionStatus();
  }, []);

  useEffect(() => {
    let listener: any;

    const setupListener = async () => {
      const checkPermissionOnResume = async () => {
        if (Capacitor.getPlatform() === 'web') return;

        const { receive } = await FirebaseMessaging.checkPermissions();
        setHasNotificationPermission(receive === 'granted');
      };

      // Wait for the listener to be added
      listener = await App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          checkPermissionOnResume();
        }
      });
    };

    setupListener();

    return () => {
      // Cleanup listener when component unmounts
      if (listener) {
        listener.remove();
      }
    };
  }, []);

  const openNotificationSettings = async () => {
    try {
      // Check if permissions were previously denied
      const currentPermission = await FirebaseMessaging.checkPermissions();
      const wasPermissionRequested = currentPermission.receive !== 'prompt';

      if (!wasPermissionRequested) {
        // First time requesting permissions
        const result = await FirebaseMessaging.requestPermissions();
        if (result.receive === 'granted') {
          setHasNotificationPermission(true);
        }
        // If denied, just return without opening settings
        return;
      }

      // If permissions were previously denied, open settings
      await NativeSettings.open({
        optionAndroid: AndroidSettings.AppNotification,
        optionIOS: IOSSettings.AppNotification,
      });

      // Check permissions after opening settings
      const { receive } = await FirebaseMessaging.checkPermissions();
      setHasNotificationPermission(receive === 'granted');
    } catch (error) {
      console.error('Error opening settings:', error);
      // Fallback: try to open general settings
      try {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.ApplicationDetails,
          optionIOS: IOSSettings.App,
        });
      } catch (fallbackError) {
        console.error('Error opening general settings:', fallbackError);
        alert('Please open settings manually to enable notifications');
      }
    }
  };

  const styles = {
    darkmodeStyle: css`
      html[data-theme='dark'] & {
        background-color: ${colors.liquorice};
        color: ${colors.sugar};
      }
    `,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRestartOnboarding = () => {
    localStorage.removeItem('hasCompletedOnboarding');
    history.push('/onboarding');
  };

  const handleThemeChange = (newTheme: Theme) => {
    setCurrentTheme(newTheme);
    setTheme(newTheme);
  };

  return (
    <Page showNavigationBar>
      <FeedbackTakeover isShown={isFeedbackTakeoverShown} onClose={() => setIsFeedbackTakeoverShown(false)} />

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        ✨ Bloom Pro
      </h1>
      <p
        css={css`
          margin: ${spacing.deci} 0;
          color: ${colors.tungsten};
          ${fontStyles.cat}
          ${styles.darkmodeStyle}
        `}
      >
        {isSubscribed
          ? 'Your membership is active — thanks for the support!'
          : 'Unlock earnings call insights, curated news, collections, and more when you subscribe.'}
      </p>

      {isSubscribed && (
        <RowButton
          onClick={() => {
            window.location.href =
              Capacitor.getPlatform() === 'android'
                ? 'https://play.google.com/store/account/subscriptions'
                : 'https://apps.apple.com/account/subscriptions';
          }}
        >
          Manage subscription
        </RowButton>
      )}
      {!isSubscribed && (
        <div>
          <RowButton
            onClick={() => {
              stores.General.update((s) => {
                s.isSubscriptionTakeoverShown = true;
              });
            }}
          >
            Learn more
          </RowButton>
          <RowButton
            isLoading={restoreMutation.isLoading}
            disabled={restoreMutation.isLoading}
            onClick={() => {
              (async () => {
                try {
                  const { customerInfo } = await restoreMutation.mutateAsync();
                  stores.Purchase.update((s) => {
                    s.purchaserInfo = customerInfo;
                  });
                } catch (error) {
                  console.error('Failed to restore purchases:', error);
                  toast('We could not find your subscription.');
                }
              })();
            }}
          >
            Restore purchase
          </RowButton>
        </div>
      )}
      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        🔔 Notifications
      </h1>
      {hasNotificationPermission ? (
        <>
          <p
            css={css`
              margin: ${spacing.deci} 0;
              color: ${colors.tungsten};
              ${fontStyles.cat}
              ${styles.darkmodeStyle}
            `}
          >
            Configure which alerts you’d like to receive for stocks on your watchlist
          </p>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing.centi};
            `}
          >
            {preferences.map((pref) => (
              <div
                key={pref.id}
                css={css`
                  padding-bottom: ${spacing.deci};
                  border-radius: 8px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <p
                    css={css`
                      ${fontStyles.cat};
                      color: var(--text-color);
                    `}
                  >
                    {pref.description}
                  </p>
                  <label
                    css={css`
                      position: relative;
                      display: inline-block;
                      width: 45px;
                      height: 25px;
                      padding-bottom: 10px;

                      input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                      }

                      span {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #ccc;
                        transition: 0.4s;
                        border-radius: 34px;

                        &:before {
                          position: absolute;
                          content: '';
                          height: 20px;
                          width: 20px;
                          left: 3px;
                          bottom: 3px;
                          background-color: white;
                          transition: 0.4s;
                          border-radius: 50%;
                        }
                      }

                      input:checked + span {
                        background-color: ${colors.petrolBlue};
                      }

                      input:checked + span:before {
                        transform: translateX(20px);
                      }
                    `}
                  >
                    <input type="checkbox" checked={pref.enabled} onChange={() => void handleToggle(pref.id)} />
                    <span></span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <p
            css={css`
              margin: ${spacing.deci} 0;
              color: ${colors.tungsten};
              ${fontStyles.cat}
              ${styles.darkmodeStyle}
            `}
          >
            Notifications are currently disabled for Bloom. Please re-activate them if you want to receive alerts for
            news or change percents.
          </p>
          <Button block variant="secondary" onClick={openNotificationSettings}>
            Go to Notification Settings
          </Button>
        </>
      )}
      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.deci} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        💬 Community
      </h1>
      <a
        href={discordInviteUrl}
        css={css`
          color: ${colors.liquorice};
          ${styles.darkmodeStyle}
        `}
      >
        <RowButton>Join the discussion on Discord</RowButton>
      </a>
      <RowButton onClick={share}>Share the app</RowButton>

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        📩 Feedback
      </h1>
      <p
        css={css`
          margin: ${spacing.deci} 0;
          color: ${colors.tungsten};
          ${fontStyles.cat}
          ${styles.darkmodeStyle}
        `}
      >
        We appreciate your feedback! Let us know how we can help you invest with confidence.
      </p>

      <RowButton onClick={() => setIsFeedbackTakeoverShown(true)}>Send us feedback</RowButton>
      <RowButton
        onClick={() =>
          window.open(
            Capacitor.getPlatform() === 'android'
              ? 'https://play.google.com/store/apps/details?id=com.bloom.invest'
              : 'https://apps.apple.com/us/app/bloom-stock-market-research/id1436348671',
            '_blank',
          )
        }
      >
        Rate us on {Capacitor.getPlatform() === 'android' ? 'Google Play' : 'the App Store'}
      </RowButton>

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        🎨 Personalization
      </h1>

      <RowButton onClick={handleRestartOnboarding}>Restart onboarding</RowButton>
      <ThemeSelector selectedTheme={currentTheme} onChange={handleThemeChange} />

      <h1
        css={css`
          color: ${colors.liquorice};
          margin: ${spacing.regular} 0 ${spacing.deci} 0;
          ${fontStyles.shark};
          ${styles.darkmodeStyle}
        `}
      >
        ⚖️ Legal
      </h1>
      <RowButton
        onClick={() =>
          window.open(
            'https://cliff-ornament-596.notion.site/Bloom-Disclaimer-44a8d3a0a4dd4e718dbff991eba5d2b9',
            '_blank',
          )
        }
      >
        Disclaimer
      </RowButton>
      <RowButton
        onClick={() =>
          window.open('https://www.notion.so/Bloom-Terms-and-Conditions-b41a2b80c0b14429801adf810856b1d3', '_blank')
        }
      >
        Terms and Conditions
      </RowButton>
      <RowButton
        onClick={() =>
          window.open('https://www.notion.so/Bloom-Privacy-Policy-0c94935f49344ce4957fe459f4cf128d', '_blank')
        }
      >
        Privacy Policy
      </RowButton>
    </Page>
  );
};

export default MorePage;
