import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';
import { spacing, fontStyles, colors, fontFamilies } from '../../styles';
import * as stores from '../../stores';
import OnboardingSearch from './OnboardingSearch';
import OnboardingResults from './OnboardingResults';

const suggestedStocks = [
  { symbol: 'AAPL', name: 'Apple Inc.' },
  { symbol: 'MSFT', name: 'Microsoft' },
  { symbol: 'GOOGL', name: 'Alphabet' },
  { symbol: 'META', name: 'Meta' },
  { symbol: 'TSLA', name: 'Tesla' },
  { symbol: 'AMZN', name: 'Amazon' },
  { symbol: 'NVDA', name: 'NVIDIA' },
];

const WatchlistSearchPage: FC = () => {
  const [suggestedList, setSuggestedList] = useState(suggestedStocks);

  const handleResultClick = (symbol: string, name: string) => {
    stores.General.update((s) => {
      s.watchlist = {
        ...s.watchlist,
        [symbol]: true,
      };
    });
    localStorage.setItem('hasCompletedOnboarding', 'true');

    setSuggestedList((prev) => {
      if (!prev.find((stock) => stock.symbol === symbol)) {
        return [{ symbol, name }, ...prev];
      }
      return prev;
    });
  };

  return (
    <div
      css={css`
        min-height: 100vh;
        background-color: var(--background-color);
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 80px;
      `}
    >
      <div
        css={[
          css`
            transition: all 0.3s ease;
            text-align: center;
            margin-top: ${spacing.giga};
            margin-bottom: ${spacing.hecto};
          `,
        ]}
      >
        <h1
          css={[
            css`
              font-size: 30px;
              font-weight: 500;
              line-height: 30px;
              margin-bottom: ${spacing.regular};
              color: var(--text-color);
              transition: all 0.3s ease;
              font-family: ${fontFamilies.helveticaNowText};
            `,
          ]}
        >
          Build your watchlist
        </h1>
        <p
          css={css`
            ${fontStyles.dog};
            color: var(--secondary-text);
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
          `}
        >
          Select popular stocks to follow or search for specific ones.
        </p>
      </div>

      {/* Search */}
      <div
        css={css`
          padding: ${spacing.regular};
          position: relative;
          z-index: 1;
        `}
      >
        <OnboardingSearch
          autoFocus
          onResultClick={handleResultClick}
          onStockAdded={(stock) => {
            setSuggestedList((prev) => {
              if (!prev.find((s) => s.symbol === stock.symbol)) {
                return [stock, ...prev];
              }
              return prev;
            });
          }}
        />
      </div>

      {/* Popular Stocks */}
      <div
        css={css`
          padding: 0 ${spacing.regular};
          margin-top: ${spacing.deka};
        `}
      >
        <OnboardingResults investments={suggestedList} onResultClick={handleResultClick} />
      </div>

      {/* View Watchlist Button */}
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: ${spacing.regular};
          background-color: ${colors.petrolBlue};
          margin-bottom: ${spacing.kilo};
          border-radius: ${spacing.milli};
          z-index: 2;
        `}
      >
        <NavLink
          exact
          to="/notification-preferences"
          css={css`
            display: block;
            text-align: center;
            padding: ${spacing.deci};
            color: ${colors.sugar};
            text-decoration: none;
            ${fontStyles.horse}
          `}
        >
          Continue
        </NavLink>
      </div>
    </div>
  );
};

export default WatchlistSearchPage;
