import { FC, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { colors, spacing } from '../../../styles';
import CollectionCard from '../../CollectionCard';
import { getCollectionImages } from '../../../utils/getImages';
import Button from '../../Button';
import * as stores from '../../../stores';
import { DataCollections } from '../types';

const styles = {
  container: css`
    overflow-x: auto;
    white-space: nowrap;
    margin: ${spacing.deci} 0;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `,
  item: css`
    display: inline-block;
    width: 160px;
    height: 180px;
    margin-right: ${spacing.centi};
    position: relative;
  `,
  itemContent: css`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
  `,
  cardWrapper: css`
    width: 100%;
    height: 100%;
  `,
};

const RelatedCollections: FC<any> = ({ dataCollections }) => {
  const { data: collections, isLoading, isError }: DataCollections = dataCollections;
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const [imageMap, setImageMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const updateImages = () => {
      const newImageMap: Record<string, string> = {};
      collections?.forEach((collection) => {
        const imageSrc = getCollectionImages(collection.imageId);
        if (imageSrc && collection.imageId) {
          newImageMap[collection.imageId] = imageSrc;
        }
      });
      setImageMap(newImageMap);
    };

    updateImages();

    window.addEventListener('themeChanged', updateImages);
    return () => window.removeEventListener('themeChanged', updateImages);
  }, [collections]);

  if (isLoading) return <Spinner color={colors.cloud} size="lg" />;
  if (isError) return null;

  return (
    <div css={styles.container}>
      {collections?.map((collection) => {
        const imageSrc = imageMap[collection.imageId ?? ''];
        if (!imageSrc) return null;

        const isRestricted =
          collection.restrictedToSubscribers &&
          !purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

        const cardComponent = <CollectionCard name={collection.name} imageSrc={imageSrc} isLocked={isRestricted} />;

        return (
          <div key={collection.id} css={styles.item}>
            <div css={styles.itemContent}>
              <div css={styles.cardWrapper}>
                {isRestricted ? (
                  <Button
                    onClick={() =>
                      stores.General.update((s) => {
                        s.isSubscriptionTakeoverShown = true;
                      })
                    }
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  >
                    {cardComponent}
                  </Button>
                ) : (
                  <Link to={`/collection/${collection.id}`}>{cardComponent}</Link>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RelatedCollections;
