import { FC, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { fontStyles, spacing } from '../../styles';

interface Props {
  description: string;
  lightImage: string;
  darkImage: string;
  isChecked: boolean;
  onToggle: () => void;
}

const OnboardingSlide: FC<Props> = ({ description, lightImage, darkImage, isChecked, onToggle }) => {
  const [currentImage, setCurrentImage] = useState(lightImage);

  useEffect(() => {
    const updateImage = () => {
      const isDark = document.documentElement.getAttribute('data-theme') === 'dark';
      setCurrentImage(isDark ? darkImage : lightImage);
    };

    updateImage();
    window.addEventListener('themeChanged', updateImage);
    return () => window.removeEventListener('themeChanged', updateImage);
  }, [lightImage, darkImage]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${spacing.regular};
        width: 100%;
        padding: ${spacing.milli};
        cursor: pointer;
      `}
      onClick={onToggle}
    >
      <div
        css={css`
          flex: 0 0 auto;
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img
          src={currentImage}
          title="Onboarding image"
          alt="Onboarding"
          css={css`
            width: 100%;
            height: 100%;
            object-fit: contain;
          `}
        />
      </div>

      <p
        css={css`
          flex: 1;
          ${fontStyles.cat};
          text-align: left;
          margin: 0;
          font-weight: 400;
        `}
      >
        {description}
      </p>

      <div
        css={css`
          width: 24px;
          height: 24px;
          border: 2px solid ${isChecked ? 'var(--link-color)' : 'var(--border-color)'};
          border-radius: 4px;
          background-color: ${isChecked ? 'var(--link-color)' : 'transparent'};
          transition: all 0.2s ease;
          position: relative;
          pointer-events: none;

          ${isChecked &&
          `
            &:after {
              content: '';
              position: absolute;
              left: 7px;
              top: 3px;
              width: 6px;
              height: 11px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          `}
        `}
      />
    </div>
  );
};

export default OnboardingSlide;
