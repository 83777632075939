import { useState } from 'react';
import { PushNotificationService } from '../services/pushNotificationService';
import { General } from '../stores';

export interface NotificationPreference {
  id: string;
  description: string;
  enabled: boolean;
}

const DEFAULT_PREFERENCES: NotificationPreference[] = [
  {
    id: 'daily_alerts',
    description: 'Daily change +- 5%',
    enabled: false,
  },
  {
    id: 'weekly_alerts',
    description: 'Weekly change +- 10%',
    enabled: false,
  },
  {
    id: 'news_alerts',
    description: 'New news articles',
    enabled: false,
  },
];

export const useNotificationPreferences = () => {
  const watchlist = General.useState((s) => s.watchlist);

  const [preferences, setPreferences] = useState<NotificationPreference[]>(() => {
    const savedPreferences = localStorage.getItem('notificationPreferences');
    return savedPreferences ? JSON.parse(savedPreferences) : DEFAULT_PREFERENCES;
  });

  const handleToggle = async (id: string): Promise<void> => {
    const newPreferences = preferences.map((pref) => (pref.id === id ? { ...pref, enabled: !pref.enabled } : pref));

    const enabledTypes = newPreferences.filter((pref) => pref.enabled).map((pref) => pref.id);

    const watchlistSymbols = Object.keys(watchlist).filter((symbol) => watchlist[symbol]);

    if (enabledTypes.length > 0) {
      try {
        const permissionGranted = await PushNotificationService.initialize({
          notificationTypes: enabledTypes,
          watchlistSymbols,
        });

        if (!permissionGranted) {
          const disabledPreferences = preferences.map((pref) => ({
            ...pref,
            enabled: false,
          }));
          setPreferences(disabledPreferences);
          localStorage.setItem('notificationPreferences', JSON.stringify(disabledPreferences));
          return;
        }
      } catch (error) {
        console.error('Failed to initialize notifications:', error);
        return;
      }
    }

    setPreferences(newPreferences);
    localStorage.setItem('notificationPreferences', JSON.stringify(newPreferences));
  };

  return {
    preferences,
    handleToggle,
  };
};
